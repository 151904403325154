<template>
    <centered-loader></centered-loader>
</template>

<script>
import StartupMixin from "../mixins/StartupMixin";
import CenteredLoader from "../components/loaders/CenteredLoader";

export default {
    mixins: [StartupMixin],
    components: {
        CenteredLoader
    },
    data() {
        return {
            homepage: {
                settingsLoaded: false
            }
        }
    },
    methods: {
        _hook_onSettingsLoaded(settings) {
            if (this.homepage.settingsLoaded) return;
            this.homepage.settingsLoaded = true;
            this.redirectToCampaign(settings);
        },
        redirectToCampaign(settings) {
            const campaign = settings.campaign || null;

            if (!campaign) {
                throw new Error('Campaign not found');
            }

            const query = this.$route.query.c
                ? {c: this.$route.query.c}
                : null;

            this.$router.push({
                name: campaign,
                query: query
            });
        }
    }
}
</script>

