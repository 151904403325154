import {mapMutations} from 'vuex';
import CommonMixin from "./CommonMixin";

export default {
    mixins: [
        CommonMixin
    ],
    methods: {
        ...mapMutations([
            '_store_setAuthSettings'
        ]),
        _startup_setUidSettings() {
            this._common_getSettings()
                .once('value')
                .then(s => s.val())
                .then(settings => {
                    this._store_setAuthSettings(settings);
                    this._hook_onSettingsLoaded(settings);
                });
        },
        // eslint-disable-next-line no-unused-vars
        _hook_onSettingsLoaded(settings) {

        }
    },
    mounted() {
        this._startup_setUidSettings();
    }
}
